import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { fetchWithToken } from '../../config/helper';
import { notification_campaigns_url } from '../../config/api';
import moment from 'moment';
import SectionHeader from '../../common/Section/SectionHeader';
import SectionBody from '../../common/Section/SectionBody';

interface NotificationCampaign {
    _id: string;
    title: string;
    body: string;
    status: string;
    totalUsers: number;
    processedUsers: number;
    successCount: number;
    failureCount: number;
    createdAt: string;
    completedAt?: string;
}

const NotificationCampaigns = () => {
    const [campaigns, setCampaigns] = useState<NotificationCampaign[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchCampaigns = async () => {
        setLoading(true);
        try {
            const response = await fetchWithToken(notification_campaigns_url, 'get');
            const data = await response.json();
            setCampaigns(data.results || []);
        } catch (error) {
            console.error('Error fetching campaigns:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCampaigns();
        const interval = setInterval(fetchCampaigns, 10000);
        return () => clearInterval(interval);
    }, []);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Total Users',
            dataIndex: 'totalUsers',
            key: 'totalUsers',
        },
        {
            title: 'Processed',
            dataIndex: 'processedUsers',
            key: 'processedUsers',
        },
        {
            title: 'Success',
            dataIndex: 'successCount',
            key: 'successCount',
        },
        {
            title: 'Failed',
            dataIndex: 'failureCount',
            key: 'failureCount',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: string) => moment(date).format('DD-MMM-YYYY HH:mm:ss'),
        },
        {
            title: 'Completed At',
            dataIndex: 'completedAt',
            key: 'completedAt',
            render: (date: string) => date ? moment(date).format('DD-MMM-YYYY HH:mm:ss') : '-',
        },
    ];

    return (
        <>
            <SectionHeader heading="Notification Campaigns">
                <Button
                    icon={<ReloadOutlined />}
                    onClick={fetchCampaigns}
                    loading={loading}
                >
                    Refresh
                </Button>
            </SectionHeader>
            <SectionBody>
                <Table
                    // No need since the button is already showing loading
                    // loading={loading}
                    dataSource={campaigns}
                    columns={columns}
                    rowKey="_id"
                    scroll={{ y: '65vh' }}
                    pagination={false}
                />
            </SectionBody>
        </>
    );
};

export default NotificationCampaigns; 