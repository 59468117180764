export const menuItems = [
  {
    key: "dashboard",
    label: "Dashboard",
    activePath: "/dashboard",
    link: "/dashboard"
  },
  {
    key: "user",
    label: "Users",
    activePath: "/user",
    link: "/user"
  },
  {
    key: "tabler",
    label: "Tables & Events",
    activePath: "/tabler",
    link: "/tabler"
  },
  {
    key: "kyc",
    label: "KYCs",
    activePath: "/kyc",
    link: "/kyc"
  },
  {
    key: "boost",
    label: "Boost",
    activePath: "/boost",
    link: "/boost"
  },
  {
    key: "referral",
    label: "Referral",
    activePath: "/referral",
    link: "/referral"
  },
  {
    key: "reports",
    label: "Reports",
    activePath: "/reports",
    link: "/reports"
  },
  {
    key: "notification-campaigns",
    label: "Notification Campaigns",
    activePath: "/notification-campaigns",
    link: "/notification-campaigns"
  }
];


export const userOption = [
  {
    key: "profile",
    label: "profile",
    link: "/profile"
  },
  {
    key: "change_password",
    label: "change password",
    link: "/changePassword"

  },
  {
    key: "logout",
    label: "logout",
    link: "/logout"
  }
];
